.ContactLinksPanel {
    background-color: rgba(28, 28, 28, 0.87);

    margin-top: 10%;

    width: 21vmin;

    border-radius: 2vmin;
}

.pill{
    position: fixed;
    margin-top: 0;

    height: 4vmin;
    width: 21vmin;
    border-radius: 2vmin;

    background-color: rgba(217, 217, 217, 0.81);
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .ContactLinksPanel {
        margin: 10vh auto;
        background: rgba(28, 28, 28, 0.87);
        min-width: 50%;
        max-width: 60%;
        border-radius: 2.2rem;
    }
}

/* Small phones */
@media (max-width: 320px) {

}


