.Text {
    /* padding: 0 auto; */
    font-family: 'Inter';
    color: aliceblue;
    line-height: 2.4rem;
    font-size: 1.5rem;
    stroke: none;
    font-weight: lighter;
    padding-right: 5%;
    max-width: 90rem;
    
    margin: 10% 0 5% 0;

}


/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {

}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .Text{
        /*margin: 10%;*/
        padding: 0 7% 7%;
        margin: 0;
    }
}

/* Small phones */
@media (max-width: 320px) {

}