.Item {
    position: relative;
    padding: 0;
}

.itemLink {
    display: flex;
    position: relative;

    font-family: Arial, sans-serif;
    font-size: 1.2rem;
    text-decoration: none;
    color: black;

    justify-content: center;

    width: 6.5rem;
    padding: 0.9rem 0.88rem;
    z-index: 1;
}

.Item.active .itemLink {
    color: white;
}

.pill {
    position: absolute;
    inset: 0;
    overflow: hidden;
}

.pill::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(62, 65, 66, 0.1);
    border-radius: 15rem;
    opacity: 0;
    visibility: hidden;
    transition-property: visibility, opacity, transform;
    transition-duration: .2s;
}

@media (prefers-reduced-motion: reduce) {
    .pill::before {
        transition: none;
    }
}

.Item:hover > .pill::before {
    opacity: 1;
    visibility: visible;
}

.Item:has(~ .Item:hover) > .pill::before {
    opacity: 1;
    transform: translateX(100%);
}

.Item:hover ~ .Item > .pill::before {
    opacity: 1;
    transform: translateX(-100%);
}

.Item:has(~ .Item + .Item:hover) > .pill::before {
    transition: none;
}

.Item:hover + .Item ~ .Item > .pill::before {
    transition: none;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {

}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .Item{
        width: 8.26rem;
    }
    .itemLink{
        padding: 1rem;
        line-height: 1rem;
    }
}

/* Small phones */
@media (max-width: 320px) {

}
