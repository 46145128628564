.Menu {
    display: flex;
    position: relative;
    height: 3rem;
    justify-content: center;
    background: rgba(217, 217, 217, 0.4);
    padding: 0;
    border-radius: 15rem;
    width: 33rem;
    margin: 1rem auto auto;
}

.pill{
    content: "";
    position: absolute;
    inset: 0;
    left: 0;
    background-color: rgba(62, 65, 66, 0.7);
    border-radius: 15rem;
    width: 8.26rem;
    transition: left 0.3s ease;

    /*visibility: hidden;*/
}


/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {

}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .Menu {
        width: 33.04rem;
        height: 3rem;
    }
    .pill{
        width: 8.26rem;
    }
}

/* Small phones */
@media (max-width: 320px) {

}
