.ProjectName {
    font-family: "Inter", serif;
    font-size: 2.2rem;

    white-space: nowrap;
}
.ProjectName p{
    margin: 3% 0 0 18%;
    padding: 0;
}


/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .ProjectName{
        margin: 3% 0 0 18%;
    }
}

/* Small phones */
@media (max-width: 320px) {
}