.App{
    overflow-x: hidden;
    overflow-y: hidden;

    perspective: 1px;
}
.App {
  background-image:  url("../public/gory-gorizontalnye-31.webp");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;

  min-height: 100vh;
  min-width: 100vw;

  margin: 0;

}

/* Large desktops and laptops */
@media (min-width: 1200px) {
    html{
        font-size: 16px;
    }
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
    html{
        font-size: 14px;
    }
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
    html{
        font-size: 12px;
    }
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
    html{
        font-size: 10px;
    }
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    html{
        font-size: 8px;
    }
}

/* Small phones */
@media (max-width: 320px) {
    html{
        font-size: 6px;
    }
}