.StackListPanel {
    padding: 0;
    border: 0;
    position: relative;
    /*align-content: center;*/

    margin: 20vh auto;
    background: rgba(28, 28, 28, 0.87);
    min-width: 10%;
    max-width: 20%;
    border-radius: 1.3rem;

    transition: transform 1s ease;
    transform: translateX(0);
}

.ErrorMessage{
    padding: 0 30px;
    color: red;
    text-align: center;
}

.StackListPanel.active {
    transform: translateX(-30vw);
}
.pill{
    visibility: hidden;
}

.pill.active{
    content: "";
    position: absolute;
    inset: 0;
    left: 0;
    background-color: rgba(217, 217, 217, 0.81);
    border-radius: 15rem;
    width: 100%;
    height: 2.5rem;
    transition: top 0.3s ease;
    visibility: visible;
}



/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {

}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .StackListPanel {
        margin: 10vh auto;
        background: rgba(28, 28, 28, 0.87);
        min-width: 20%;
        max-width: 40%;
        border-radius: 1.2rem;

        transition: transform 1s ease;
        transform: translateX(0);
    }
    .pill.active{
        height: 3.5rem;
        border-radius: 1.2rem;
    }

    .StackListPanel.active {
        transform: translateX(-41vw);
    }
}

/* Small phones */
@media (max-width: 320px) {
}
