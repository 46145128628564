.ContactLinkItem {
    /*display: inline-block;*/
    height: 4vmin;
    width: 100%;
    position: relative;
    /*padding: 0 0.5vmin;*/

    text-align: left;

    /*background-color: azure;*/
    /*border: black 1px solid;*/
    border-radius: 2vmin;

}

.ContactLinkItem:hover{
    cursor: pointer;
}

.ContactLinkItem a{
    position: relative;
    margin: 0 1vmin;
    padding: 0;
    line-height: 4vmin;
    font-family: "Inter";
    color: azure;
    font-size: 2vmin;
    text-decoration: none;

    z-index: 1;
}

.ContactLinkItem img{
    position: relative;
    float: left;
    width: 3.4vmin;
    margin-left: 0.3vmin;
    margin-top: 0.3vmin;
    border-radius: 40px;

    opacity: 0;

    transition: opacity 0.3s ease;
    z-index: 1;
}

.ContactLinkItem:hover img{
    opacity: 1;
    /*z-index: 1;*/
}
.ContactLinkItem:hover a{
    color: #222;
}

.pill {
    position: absolute;
    inset: 0;
    overflow: hidden;
}

.pill::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(217, 217, 217, 0.81);
    border-radius: 2vmin;
    opacity: 0;
    visibility: hidden;
    transition-property: visibility, opacity, transform;
    transition-duration: .2s;
}

@media (prefers-reduced-motion: reduce) {
    .pill::before {
        transition: none;
    }
}

.ContactLinkItem:hover > .pill::before {
    opacity: 1;
    visibility: visible;
}

.ContactLinkItem:has(~ .ContactLinkItem:hover) > .pill::before {
    opacity: 1;
    transform: translateY(100%);
}

.ContactLinkItem:hover ~ .ContactLinkItem > .pill::before {
    opacity: 1;
    transform: translateY(-100%);
}

.ContactLinkItem:has(~ .ContactLinkItem + .ContactLinkItem:hover) > .pill::before {
    transition: none;
}

.ContactLinkItem:hover + .ContactLinkItem ~ .ContactLinkItem > .pill::before {
    transition: none;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .ContactLinkItem{
        height: 4.4rem;
        /*text-align: center;*/
    }
    .ContactLinkItem a{
        font-size: 2rem;
        line-height: 4.4rem;
    }
    .ContactLinkItem img{
        width: 3.8rem;
        margin: 0.4rem auto auto 0.4rem;
    }
    .pill:before{
        border-radius: 3rem;
    }
}

/* Small phones */
@media (max-width: 320px) {
}
