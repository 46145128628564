body {
  /* background-image: url("../public/gory-gorizontalnye-31.webp");
  background-size: cover;
  background-attachment: fixed; */
  margin: 0;
  overflow-x: hidden;
}

/*Убрать полосу прокрутки у элемента*/
.element::-webkit-scrollbar {
  width: 0;
}

/*Убрать полосу прокрутки для всей страницы*/
::-webkit-scrollbar {
  width: 0;
}

@font-face {
  font-family: Inter;
  src: url("../fonts/Inter-VariableFont_opsz,wght.ttf");
}


/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
}

/* Small phones */
@media (max-width: 320px) {
}