.Image {
    height: 18rem;
    width: 100%;
    background-color: aliceblue;
    border-radius: 1.5rem;
    min-height: 3rem;

    -webkit-transition: all .5s;
    transition: all .5s;
}
.Image:hover{
    cursor: pointer;
    transform: scale(1.1) rotate(1deg) translate(5px);
    transition: transform .5s ease;

}

.Image:active{
    transform: scale(3);
    transition: transform .2s;
}

.ProfileImageSrc{
    height: 18rem;
    width: 100%;
    border-radius: 1.5rem;
    object-fit: cover;

}


/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {

}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .Image{
        margin: 10% auto;
    }
}

/* Small phones */
@media (max-width: 320px) {

}