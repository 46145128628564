.StackItemInformation{
    background-color: rgba(217, 217, 217, 0.83);
    max-height: 0;
    min-height: 0;

    border-radius: 1.5rem;
    margin: 13% 0 auto 0;

    opacity: 0;

    /*transition: max-height 2s ease, opacity 1s ease, min-height 3s ease, margin 2s ease;*/
    transition: min-height 2s ease, opacity 1s ease, max-height 3s ease, margin 2s ease;

    font-family: "Inter", serif;
}

.StackItemInformation.active{

    max-height: fit-content;
    min-height: 50vh;
    opacity: 1;

    margin: 0;

}

.Back{
    position:absolute;

    max-width: 60%;
    min-height: 50vh;

    margin: 5% -25% auto auto;
    z-index: -1;
}


.TechName {
    font-size: 2rem;
    text-align: center;
    padding-top: 1rem;

    margin: 0;
}

.TechDescription {
    font-size: 1.4rem;
    line-height: 2rem;

    padding: 2rem;
    margin: 0;
}

.StackItemInformation p{
    opacity: 1;
}


/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .StackItemInformation.active {
        margin: 10% auto auto 0;
        min-height: 30vh;
        width: 70vw;
    }
    .Back{
        margin: 5% -10% auto auto;
    }
}

/* Small phones */
@media (max-width: 320px) {
}