.Project {
    display: grid;
    grid-template-columns: 27% auto;
    grid-template-rows: 4rem auto;

    column-gap: 3rem;
    row-gap: 1rem;

    background-color: rgba(230, 230, 230, 1);
    margin: 1% 1% 3%;
    border-radius: 1rem;

}

.projectContent{

}

/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
    .Project{
        column-gap: 0;
        grid-template-columns: 40% auto;
    }
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .Project{
        column-span: 2;
        grid-template-rows: 4rem auto auto;
    }
}

/* Small phones */
@media (max-width: 320px) {
}