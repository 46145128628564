.ProjectStack {
    font-family: "Inter";
    font-size: 1.3rem;
    margin: 0;
}

.StackList{
    margin: 0 auto 5% 4%;
}

.StackListItem{

}

/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {

}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .ProjectStack {
        grid-column-start: 1;
        grid-column-end: 3;
    }
    .ProjectStack ul{
        padding-left: 5%;
    }

}

/* Small phones */
@media (max-width: 320px) {
}