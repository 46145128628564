.MainCard {
    display: flex;
    margin: 5% auto;
    max-height: 100%;
    max-width: 70%;
    align-items: center;
    background-color: rgba(28, 28, 28, 0.87);
    border-radius: 1.5rem;
}

.ErrorMessage{
    color: red;
    text-align: center;
}
.mask{

}

.ProfileCard{
    object-fit: cover;
    padding: 1% 5% 7%;

    width: 40%;
    min-width: 16%;
    position: relative;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {

}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {

}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {

}

/* Landscape phones and smaller */
@media (max-width: 480px) {
    .MainCard{
        display: block;
    }
    .ProfileCard{
        display: flex;
        width: 80%;
        margin: 10% auto 0 auto;
        padding-bottom: 0;
    }
}

/* Small phones */
@media (max-width: 320px) {

}

