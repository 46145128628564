.StackListItem {
    height: 2.5rem;
    position: relative;
    padding: 0 0.5rem;

    /*text-align: center;*/
}
.link{
    position: relative;
    margin: 0 1rem;
    padding: 0;
    line-height: 2.5rem;
    font-family: "Inter";
    color: azure;
    font-size: 1.3rem;
    text-decoration: none;
}

.StackListItem:hover{
    cursor: pointer;
}

.StackListItem.active .link {
    z-index: 1;
    color: black;
}

.pill {
    position: absolute;
    inset: 0;
    overflow: hidden;
}

.pill::before {
    content: "";
    position: absolute;
    inset: 0;
    background-color: rgba(217, 217, 217, 0.1);
    border-radius: 1.2rem;
    opacity: 0;
    visibility: hidden;
    transition-property: visibility, opacity, transform;
    transition-duration: .2s;
}

@media (prefers-reduced-motion: reduce) {
    .pill::before {
        transition: none;
    }
}

.StackListItem:hover > .pill::before {
    opacity: 1;
    visibility: visible;
}

.StackListItem:has(~ .StackListItem:hover) > .pill::before {
    opacity: 1;
    transform: translateY(100%);
}

.StackListItem:hover ~ .StackListItem > .pill::before {
    opacity: 1;
    transform: translateY(-100%);
}

.StackListItem:has(~ .StackListItem + .StackListItem:hover) > .pill::before {
    transition: none;
}

.StackListItem:hover + .StackListItem ~ .StackListItem > .pill::before {
    transition: none;
}


/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .StackListItem {
        height: 3.5rem;
        position: relative;

        /*text-align: center;*/
    }
    .link{
        line-height: 3.4rem;
        font-family: "Inter";
        color: azure;
        font-size: 1.3rem;
        text-decoration: none;
    }
    .pill:before{
        border-radius:1.2rem;
    }
}

/* Small phones */
@media (max-width: 320px) {
    .StackListItem {
        height: 3vh;
        position: relative;

        /*text-align: center;*/
    }
    .link{
        line-height: 2.4rem;
        font-family: "Inter";
        color: azure;
        font-size: 1.3rem;
        text-decoration: none;
    }
}
