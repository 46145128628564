.ProjectDescription {
    font-family: "Inter";
    font-size: 1.3rem;
    line-height: 1.8rem;
    margin-bottom: 3%;
}

.ProjectDescription p {
    margin: 0;
}

/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .ProjectDescription {
        grid-column-start: 1;
        grid-column-end: 3;
        padding: 0 5% 5% 5%;
    }
}

/* Small phones */
@media (max-width: 320px) {
}