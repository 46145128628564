.ProjectsPanel {
    background-color:rgba(28, 28, 28, 0.87);
    border-radius: 1.5rem;
    margin: 10vh auto;
    max-width: 80%;
    padding: 1%;
}

.ErrorMessage{
     color: red;
     text-align: center;
 }

/* Large desktops and laptops */
@media (min-width: 1200px) {
}

/* Portrait tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {
}

/* Landscape phones and portrait tablets */
@media (min-width: 480px) and (max-width: 767px) {
}

/* Landscape phones and smaller */
@media (max-width: 479px) {
    .ProjectsPanel{
        margin: 5vh auto;
    }
}

/* Small phones */
@media (max-width: 320px) {
}